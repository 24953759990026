import { NgModule } from '@angular/core'
import { SidebarComponent } from './sidebar.component'
import {
  NbSidebarModule,
  NbMenuModule,
  NbCardModule,
  NbListModule,
  NbIconModule,
  NbButtonModule,
  NbTooltipModule,
} from '@nebular/theme'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    CommonModule,
    NbSidebarModule,
    NbCardModule,
    NbMenuModule,
    NbListModule,
    NbButtonModule,
    NbIconModule,
    NbTooltipModule,
  ],
  exports: [SidebarComponent],
})
export class SidebarModule {}
