<nb-card>
  <div class="closeModal" (click)="close()">
    <nb-icon icon="close-outline" status="danger"></nb-icon>
  </div>
  <nb-card-body>
    <div class="camera">
      <div class="camera__webcam">
        <webcam
          [imageQuality]="1"
          [trigger]="triggerObservable"
          (imageCapture)="handleImage($event)"
          (initError)="handleInitError($event)"
          [hidden]="!showWebcam"
          *ngIf="showWebcam || errors"
        ></webcam>

        <div class="snapshot" *ngIf="webcamImage">
          <div class="actionsCamera">
            <button
              nbButton
              status="danger"
              size="small"
              (click)="deleteImage()"
            >
              <nb-icon icon="trash-outline"></nb-icon>
              Apagar
            </button>
            <button
              nbButton
              status="success"
              size="small"
              (click)="saveImage()"
            >
              <nb-icon icon="checkmark-outline"></nb-icon>
              Salvar e fechar
            </button>
          </div>
          <img [src]="webcamImage.imageAsDataUrl" />
        </div>

        <div class="errorsCamera" *ngIf="errors.length > 0">
          <nb-alert status="danger">
            Error: Não foi possível iniciar a câmera, verifique se a mesma está
            conectada ou falta a liberação para o uso!
          </nb-alert>
        </div>
      </div>

      <div class="camera__btns">
        <button
          nbButton
          outline
          status="success"
          type="button"
          (click)="close()"
          class="camera__btns--back"
        >
          Cancelar
        </button>

        <button
          nbButton
          status="success"
          (click)="triggerSnapshot()"
          type="button"
          [disabled]="errors.length > 0 || webcamImage"
          class="camera__btns--next"
        >
          Tirar foto
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
