import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnDestroy {
  // webCam
  public showWebcam = true;
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  constructor(private dialogRef: NbDialogRef<CameraComponent>) {}

  triggerSnapshot(): void {
    this.trigger.next();
  }

  handleImage(webcamImage: WebcamImage): void {
    this.showWebcam = false;
    this.webcamImage = webcamImage;
  }

  deleteImage() {
    this.showWebcam = true;
    this.webcamImage = null;
  }

  saveImage() {
    const { imageAsDataUrl } = this.webcamImage;
    this.close(imageAsDataUrl);
  }

  handleInitError(error: WebcamInitError): void {
    this.showWebcam = false;
    this.errors.push(error);
    if (
      error.mediaStreamError &&
      error.mediaStreamError.name === 'NotAllowedError'
    ) {
      console.warn('O acesso à câmera não foi permitido pelo usuário!');
    }
  }
  close(imageAsDataUrl?: string) {
    this.dialogRef.close({ imageAsDataUrl });
  }

  ngOnDestroy() {
    this.showWebcam = false;
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
}
