import { Component, HostListener, OnInit } from '@angular/core';
import { NbMediaBreakpoint, NbSidebarService } from '@nebular/theme';
import { AuthService } from './services/auth.service';
import { CustomSidebarService } from './services/custom-sidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Elleve-Loja';
  isAuthenticated = false;
  isSidebarInitiallyCollapsed = false;

  constructor(
    private authService: AuthService,
    private sidebarService: NbSidebarService,
    private customSidebarService: CustomSidebarService
  ) {
    this.authService.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.isAuthenticated = loggedIn;
      }
    });
  }

  ngOnInit() {
    this.isSidebarInitiallyCollapsed = this.customSidebarService.isInitiallyCollapsed();
  }

  toggleSidebar() {
    this.sidebarService.toggle();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isSidebarInitiallyCollapsed = this.customSidebarService.isInitiallyCollapsed();
  }
}
