<nb-card>
  <div class="closeModal" (click)="close()">
    <nb-icon icon="close-outline" status="danger"></nb-icon>
  </div>
  <nb-card-header>Esqueci minha senha</nb-card-header>
  <nb-card-body>
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="f-elleve">
      <fieldset>
        <div class="row">
          <div class="col-md-12">
            <label for="usuario">Usuário</label>
            <input
              type="text"
              id="usuario"
              nbInput
              fullWidth
              fieldSize="large"
              formControlName="userName"
              placeholder="Digite o seu usuário"
            />
          </div>
        </div>
      </fieldset>
      <div class="d-flex justify-content-end mt-4">
        <button
          nbButton
          status="primary"
          type="submit"
          [disabled]="submitted || userForm.invalid"
        >
          Recuperar senha
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
