import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NbMenuItem, NbMenuService, NbSidebarService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CustomSidebarService } from 'src/app/services/custom-sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  private alive = true;
  selectedItem: string;
  isInitiallyCollapsed = false;
  isInitiallyCompacted = false;
  isCompacted = false;

  items: NbMenuItem[] = [
    // { title: 'Dashboard', icon: 'home-outline', link: 'home' },
    {
      title: 'Enviar proposta',
      icon: 'cloud-upload-outline',
      link: 'proposal',
    },

    {
      title: 'Consultar proposta(s)',
      icon: 'file-text-outline',
      link: 'consultar-proposta',
    },

    {
      title: 'Logout',
      icon: 'log-out-outline',
      selected: false,
    },
  ];

  constructor(
    private menuService: NbMenuService,
    private authService: AuthService,
    private sidebarService: NbSidebarService,
    private customSidebarService: CustomSidebarService
  ) {}

  ngOnInit() {
    this.menuService
      .onItemClick()
      .pipe(takeWhile(() => this.alive))
      .subscribe(({ item }) => {
        this.onItemSelection(item);
        this.resetSelectedItem(item.link);
      });

    this.updateSidebarStatus();
  }

  resetSelectedItem(route: string) {
    this.items.forEach((item) => {
      const { children } = item;
      if (children) {
        children.forEach((child: any) => {
          child.selected = route === child.link ? true : false;
        });
      }
    });
  }

  onItemSelection({ title }) {
    if (this.isInitiallyCollapsed) {
      this.sidebarService.toggle();
    } else if (this.isInitiallyCompacted && !this.isCompacted) {
      this.sidebarService.toggle(true);
      this.updateSidebarStatus();
    }

    switch (title) {
      case 'Logout':
        this.logout();
        break;
    }
  }

  logout() {
    this.authService.logout();
  }

  toggleSidebar() {
    this.sidebarService.toggle(true);
    this.isCompacted = !this.isCompacted;
  }

  updateSidebarStatus() {
    this.isInitiallyCollapsed = this.customSidebarService.isInitiallyCollapsed();
    this.isInitiallyCompacted = this.customSidebarService.isInitiallyCompacted();
    this.isCompacted = this.isInitiallyCompacted;
  }

  ngOnDestroy() {
    this.alive = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateSidebarStatus();
  }
}
