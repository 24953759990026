import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbToastrModule,
  NbSidebarModule,
  NbMenuModule,
  NbDialogModule,
  NbButtonModule,
  NbIconModule,
  NbTooltipModule,
  NbCardModule,
  NbAlertModule,
  NbInputModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { Interceptor } from './services/intercept.service';
import { AuthGuard } from './guards/auth.guard';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { JwtModule } from '@auth0/angular-jwt';
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { CustomCurrencyMaskConfig } from './models/currency';
import { CameraComponent } from './components/camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { RecoveryPasswordComponent } from './components/recovery-password/recovery-password.component';
import { ReactiveFormsModule } from '@angular/forms';

registerLocaleData(localePt);

export function tokenGetter() {
  return localStorage.getItem('access_token');
}
@NgModule({
  declarations: [AppComponent, CameraComponent, RecoveryPasswordComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    HttpClientModule,
    ReactiveFormsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NgxMaskModule.forRoot(),
    NbButtonModule,
    NbIconModule,
    NbTooltipModule,
    SidebarModule,
    CurrencyMaskModule,
    WebcamModule,
    NbCardModule,
    NbAlertModule,
    NbInputModule,
  ],
  providers: [
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
