<div class="logo d-flex align-items-center justify-content-center">
  <img
    src="../../../assets/images/logo.png"
    alt="Logo Elleve"
    class="d-none d-lg-block"
  />
</div>
<nb-menu tag="menu" [items]="items"></nb-menu>
<nb-list-item
  *ngIf="!isInitiallyCollapsed && isCompacted"
  class="collapse-sidebar-button position-absolute px-0 w-100 d-flex justify-content-center"
>
  <button ghost nbButton (click)="toggleSidebar()" nbTooltip="Expandir">
    <nb-icon icon="arrowhead-right-outline"></nb-icon>
  </button>
</nb-list-item>
<nb-list-item
  *ngIf="!isInitiallyCollapsed && !isCompacted"
  class="expand-sidebar-button position-absolute px-0 w-100 d-flex justify-content-center"
>
  <button ghost nbButton (click)="toggleSidebar()">
    <nb-icon icon="arrowhead-left-outline"></nb-icon>
    Esconder
  </button>
</nb-list-item>
