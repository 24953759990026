import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerService } from './server.service';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { Login, LoginResponse } from '../models/login';
import { DataUser } from '../models/user';
import { APIResponse } from '../models/response';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _accessToken = new BehaviorSubject<string>(null);
  private _loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private server: ServerService) {
    this.accessToken = this.getAccessToken();
    this.loggedIn = Boolean(this.getAccessToken());
  }

  loginUser(params: Login) {
    return this.http
      .post<APIResponse<LoginResponse>>(this.server.url(`/Login`), params)
      .pipe(catchError(this.server.handleError));
  }

  forgotPassword(userName: string) {
    return this.http
      .post<any>(this.server.url(`Login/senha/resetar`), userName)
      .pipe(catchError(this.server.handleError));
  }

  getAccessToken(): string {
    return localStorage.getItem('access-token');
  }

  logout(): void {
    localStorage.removeItem('user');
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    window.location.reload();
  }

  decodeAccessToken() {
    let token = { sid: '', id: '' };

    if (this.getAccessToken()) {
      token = jwt_decode(this.getAccessToken());
      return token;
    }
    return token;
  }

  refreshToken() {
    const { id } = this.decodeAccessToken();
    const refreshToken = localStorage.getItem('refresh-token');

    return this.http
      .post<APIResponse<LoginResponse>>(this.server.url('/Login'), {
        userName: id,
        password: refreshToken,
        grantTypes: 'refresh_token',
        resultado: 0,
      })
      .pipe(catchError(this.server.handleError));
  }

  get loggedIn$() {
    return this._loggedIn.asObservable();
  }

  set loggedIn(value: boolean) {
    this._loggedIn.next(value);
  }

  get accessToken$() {
    return this._accessToken.asObservable();
  }

  set accessToken(value: string) {
    this._accessToken.next(value);
  }

  getDataUser(): DataUser {
    return JSON.parse(localStorage.getItem('user'));
  }
}
