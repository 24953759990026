import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, throwError, Subscription, Subject } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AlertService } from './alert.service';
import { APIResponse } from '../models/response';
import { environment } from '../../environments/environment';

@Injectable()
export class Interceptor implements HttpInterceptor, OnDestroy {
  isRefreshingToken = false;
  subscriptions = new Subscription();
  accessToken: string;

  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private authService: AuthService, private alert: AlertService) {
    this.getTokenState();
  }

  getTokenState() {
    this.subscriptions.add(
      this.authService.accessToken$.subscribe((token) => {
        this.accessToken = token;
      })
    );
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const request = this.getRequestWithAuthHeader(req);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleResponseError(error, request, next);
      })
    );
  }

  handleResponseError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    const { status } = error;
    if (status === 401) {
      return this.handleResponse401(request, next);
    } else if (status === 403) {
      return this.handleResponse403(error);
    } else {
      return this.handleErrorsWithGenericResponse(error);
    }
  }

  getRequestWithAuthHeader(request: HttpRequest<any>) {
    if (request.url.includes(environment.BASE_URL)) {
      const copyOfRequest = request.clone({
        headers: request.headers.set(
          'Authorization',
          `Bearer ${this.accessToken}`
        ),
      });

      return copyOfRequest;
    } else {
      return request;
    }
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.refreshToken().pipe(
        tap(({ value: { token } }) => {
          if (token) {
            this.authService.accessToken = token.accessToken;
            sessionStorage.setItem('access-token', token.accessToken);
            localStorage.setItem('refresh-token', token.refreshToken);
            this.refreshTokenInProgress = false;
            this.tokenRefreshedSource.next();
          } else {
            return this.handleErrorRefreshToken(
              'Token de acesso não encontrado'
            );
          }
        }),
        catchError(() => {
          return this.handleErrorRefreshToken(
            'Não foi possível realizar o refresh do token do usuário'
          );
        })
      );
    }
  }

  handleErrorRefreshToken(error: string) {
    this.refreshTokenInProgress = false;
    this.authService.logout();
    return throwError(error);
  }

  handleResponse(response: any) {
    if (response && response instanceof HttpResponse) {
      const status = response.status;
      const body = response.body as APIResponse;
      if (status === 200 && body?.errors?.length) {
        this.handleResponse200WithError(response.body);
      }
    }
  }

  handleResponse200WithError(body: APIResponse) {
    this.alert.error(body.errors[0]);
  }

  handleResponse401(request: HttpRequest<any>, next: HttpHandler) {
    const isLoggedIn =
      Boolean(localStorage.getItem('access-token')) &&
      Boolean(localStorage.getItem('refresh-token'));

    if (isLoggedIn) {
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.getRequestWithAuthHeader(request);
          return next.handle(request);
        }),
        catchError((anotherError: HttpErrorResponse) => {
          return this.handleResponseError(anotherError, request, next);
        })
      );
    } else {
      const errorMessage = 'Credenciais inválidas';
      this.alert.error(errorMessage);
      return throwError(errorMessage);
    }
  }

  handleUserWithInvalidToken() {
    this.authService.logout();
    const errorMessage =
      'Sua sessão expirou. Você será redirecionado para o login.';
    this.alert.error(errorMessage);
    return throwError(errorMessage);
  }

  handleResponse403(error: HttpErrorResponse) {
    this.handleErrorsWithGenericResponse(error);
    const errorMessage = 'Sem permissão de acesso';
    this.alert.error(errorMessage);
    return throwError(errorMessage);
  }

  handleErrorsWithGenericResponse(error: HttpErrorResponse | string) {
    if (error instanceof HttpErrorResponse) {
      const arrayOfErrors = error.error?.errors;
      if (arrayOfErrors) {
        if (Array.isArray(arrayOfErrors) && arrayOfErrors.length) {
          if (
            error.status === 400 &&
            arrayOfErrors.includes(
              'Sua sessão expirou. Você será redirecionado para o login.'
            )
          ) {
            this.handleUserWithInvalidToken();
          }
          arrayOfErrors.forEach((problemMessage) =>
            this.alert.error(problemMessage)
          );
        } else if (Object.getOwnPropertyNames(arrayOfErrors).length > 0) {
          Object.getOwnPropertyNames(arrayOfErrors).forEach((propOfError) =>
            this.alert.error(arrayOfErrors[propOfError])
          );
        }
      } else {
        this.alert.error(
          typeof error.error === typeof ''
            ? error.error
            : 'Verifique sua conexão e tente novamente.',
          'Ocorreu um erro sem tratamento'
        );
      }
    } else {
      console.error(error);
    }

    return throwError(error);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
