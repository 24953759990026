<ngx-loading-bar color="#2ecc71" ref="http" height="4px"></ngx-loading-bar>
<nb-layout>
  <nb-layout-header
    fixed
    *ngIf="isSidebarInitiallyCollapsed && isAuthenticated"
  >
    <div class="w-100 d-flex justify-content-between">
      <img
        src="../../../assets/images/logo.png"
        alt="Logo Elleve"
        class="header-logo"
      />
      <button
        nbButton
        ghost
        nbTooltip="Exibir barra lateral"
        (click)="toggleSidebar()"
      >
        <nb-icon icon="menu-outline"></nb-icon>
      </button>
    </div>
  </nb-layout-header>

  <nb-sidebar
    responsive
    *ngIf="isAuthenticated"
    state="expanded"
    collapsedBreakpoints="['xs', 'is', 'sm', 'md']"
    compactedBreakpoints="['lg', 'xl']"
  >
    <app-sidebar></app-sidebar>
  </nb-sidebar>

  <nb-layout-column [style.padding]="isAuthenticated ? null : '0'">
    <router-outlet></router-outlet>
  </nb-layout-column>

  <!-- <nb-layout-footer fixed>
  </nb-layout-footer> -->
</nb-layout>
